
import React, { useState } from 'react';
import './App.css';
/*import UploadForm from './components/UploadForm';
//import Login from './components/Login';
//import Header from './components/Header';
*/
import UploadForm from './UploadForm';
import Login from './Login';
import Header from './Header';

function App() {
    const [authenticated, setAuthenticated] = useState(
        localStorage.getItem('authToken') ? true : false
    );

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setAuthenticated(false);
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto px-4 py-8">
                {authenticated ? (
                    <>
                        <Header onLogout={handleLogout} />
                        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6 mt-8">
                            <h1 className="text-2xl font-bold text-center mb-8">Audio Transcription Service</h1>
                            <UploadForm />
                        </div>
                    </>
                ) : (
                    <Login setAuthenticated={setAuthenticated} />
                )}
            </div>
        </div>
    );
}

export default App;