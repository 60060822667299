import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingSpinner from './LoadingSpinner';

function UploadForm() {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [pollingInterval, setPollingInterval] = useState(null);
    const [queuePosition, setQueuePosition] = useState(null);

    useEffect(() => {
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [pollingInterval]);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setMessage('');
        setDownloadLink('');
        setTaskId(null);
        setQueuePosition(null);
    };

    const checkTranscriptionStatus = async (taskId) => {
        try {
            const authToken = localStorage.getItem('authToken');
            const statusResponse = await axios.get(`/api/status/${taskId}`, {
                headers: {
                    'Authorization': authToken
                }
            });

            if (statusResponse.data.status === 'completed') {
                clearInterval(pollingInterval);
                setPollingInterval(null);

                const downloadResponse = await axios.get(
                    `/api/download/${taskId}`,
                    {
                        headers: {
                            'Authorization': authToken
                        }
                    }
                );

                setDownloadLink(downloadResponse.data.url);
                setMessage("Transcription completed. Click download to get your file.");
                setLoading(false);
            } else if (statusResponse.data.status === 'failed') {
                clearInterval(pollingInterval);
                setPollingInterval(null);
                setMessage(`Error: ${statusResponse.data.error || 'Transcription failed'}`);
                setLoading(false);
            }
        } catch (error) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
            setMessage("Error checking transcription status. Please try again.");
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setMessage("Please select a file first!");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        setLoading(true);
        setDownloadLink('');
        setMessage('');

        try {
            const authToken = localStorage.getItem('authToken');
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Authorization': authToken
                }
            });

            if (response.data.task_id) {
                setTaskId(response.data.task_id);
                setMessage("File uploaded successfully. Processing transcription...");

                const interval = setInterval(() => {
                    checkTranscriptionStatus(response.data.task_id);
                }, 5000);

                setPollingInterval(interval);
            } else {
                throw new Error("No task ID received");
            }
        } catch (error) {
            setMessage("Error: Upload or processing failed. Please try again later.");
            setLoading(false);
        }
    };

    const handleDownload = () => {
        if (downloadLink) {
            const link = document.createElement('a');
            link.href = downloadLink;
            link.setAttribute('download', 'transcription.txt');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input 
                type="file" 
                onChange={handleFileChange} 
                accept="audio/*" 
                disabled={loading}
            />
            <button type="submit" disabled={loading}>
                Upload
            </button>
            {message && (
                <p className={`message ${message.startsWith("Error") ? "error" : ""}`}>
                    {message}
                </p>
            )}
            {loading && <LoadingSpinner />}
            {downloadLink && (
                <button 
                    type="button" 
                    className="download" 
                    onClick={handleDownload}
                >
                    Download Transcription
                </button>
            )}
        </form>
    );
}

export default UploadForm;