import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Login.css';

function Login({ setAuthenticated }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setAuthenticated(true);
        }
    }, [setAuthenticated]);

    const handleLogin = async () => {
        try {
            const response = await axios.post('/login', { username, password });
            if (response.data.authToken) {
                localStorage.setItem('authToken', response.data.authToken);
                setAuthenticated(true);
            }
        } catch (err) {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="login-container">
            <h1 className="login-title">Login</h1>
            <div className="login-input-group">
                <input
                    type="text"
                    className="login-input"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
            <div className="login-input-group">
                <input
                    type="password"
                    className="login-input"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button className="login-button" onClick={handleLogin}>
                Login
            </button>
            {error && <p className="login-error">{error}</p>}
        </div>
    );
}

export default Login;