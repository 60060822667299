// src/components/Header.js
import React from 'react';

function Header({ onLogout }) {
    return (
        <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
                <h1 className="text-xl font-semibold text-gray-800">Transcription Service</h1>
                <button
                    onClick={onLogout}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                >
                    Logout
                </button>
            </div>
        </header>
    );
}

export default Header;