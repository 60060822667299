// src/components/LoadingSpinner.js
import React from 'react';

function LoadingSpinner() {
    return (
        <div className="flex justify-center items-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
    );
}

export default LoadingSpinner;